<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vg_mb_8">
        <el-button type="primary" size="small" @click="addRow()" icon="el-icon-plus">新增</el-button>
        <el-button type="info" size="small" class="vd_export" icon="el-icon-refresh-right" @click="refresh()"> 刷新</el-button>
      </div>
      <DynamicUTable
        ref="multiTable"
        :tableData="tableData"
        :tableRowKey="'mould_id'"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        :need-fixed-height="true"
        :rowStyle="() => ({ height: '80px' })"
        @getTableData="getTableData"
      >
        <template v-slot:operation="scope">
          <el-button v-if="scope.row.disabled" type="success" size="mini" @click="scope.row.disabled = false">编辑</el-button>
          <el-button v-else type="primary" size="mini" @click="saveRow(scope.row, scope.$index)">保存</el-button>
        </template>
      </DynamicUTable>
    </el-card>
  </div>
</template>

<script>
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { tableProperties } from '@/views/MessageManagement/MouldManage/mould';
import { mouldAPI } from '@api/modules/mould';
import { cloneDeep } from 'lodash';

export default {
  name: 'mouldList',
  components: { DynamicUTable },
  data() {
    return {
      tableData: [],
      tableProperties: tableProperties,
      loadFlag: false,
      totalPage: 0
    };
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    refresh() {
      this.$refs.multiTable.resetFields();
      this.getTableData();
    },
    async getTableData() {
      this.loadFlag = true;
      let searchForm = this.$refs.multiTable.searchForm;
      await mouldAPI.getMoulds(searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.loadFlag = false;
      });
    },
    addRow() {
      let addObj = cloneDeep(this.$refs.multiTable.originalObject);
      addObj.create_time = Date.now();
      addObj.imge_url = '';
      this.tableData.push(addObj);
    },
    async saveRow(row, index) {
      if (!row.imge_id || !row.modr_name) return this.$message.warning('请把必填补充完整!');
      let { code, data } = row.mould_id ? await mouldAPI.editMoulds(row) : await mouldAPI.addMoulds(row);
      if (code === 0) {
        await mouldAPI.getMouldById({ mould_id: data }).then(({ data: subData }) => {
          this.$set(this.tableData, index, subData);
          this.$message.success('保存成功!');
        });
      }
    }
  }
};
</script>

<style scoped>
::v-deep .elx-table .elx-body--column.col--ellipsis > .elx-cell {
  max-height: 80px;
}
</style>
