import store from '@/store';
import { keep4Decimal } from '@assets/js/regExUtil';
import { getDateNoTime } from '@assets/js/dateUtils';

export let tableProperties = [
  {
    label: '创建日期',
    prop: 'create_time',
    itemType: 'date',
    input: false,
    sortable: true,
    widthAuto: true,
    formatter: val => getDateNoTime(val, false),
    subItem: { required: false, disabled: true, maxLength: 10, type: 'date' }
  },
  {
    label: '产品图片',
    prop: 'imge_id',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: {
      required: true,
      disabled: row => row.disabled,
      maxLength: 10,
      type: 'uploadImage',
      image: row => row.imge_url,
      thumbnail: row => row.imge_url
    }
  },
  {
    label: '模具编号',
    prop: 'mould_no',
    itemType: 'input',
    input: true,
    sortable: true,
    widthAuto: true,
    labelWidth: '140px'
  },
  {
    label: '模具名称',
    prop: 'modr_name',
    itemType: 'input',
    input: true,
    sortable: false,
    widthAuto: true,
    subItem: { required: true, disabled: row => row.disabled, type: 'input' }
  },
  {
    label: '我司货号',
    prop: 'prod_no',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true
  },
  {
    label: '操作',
    prop: 'operation',
    itemType: 'input',
    input: false,
    labelWidth: '160px'
  }
];
